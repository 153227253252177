(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-event-updates.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-event-updates.js');
"use strict";

const {
  useState,
  useMemo,
  useEffect,
  useCallback
} = React;
const animationFadeOut = 'fadeOutAnimation';
const useEventUpdates = hasChangedResults => {
  const [showResultHasChanged, setShowResultHasChanged] = useState(hasChangedResults);
  useEffect(() => {
    if (hasChangedResults) {
      setShowResultHasChanged(hasChangedResults);
    }
  }, [hasChangedResults]);
  const handleAnimationEnd = useCallback(event => {
    if (event.animationName === animationFadeOut) {
      setShowResultHasChanged(false);
    }
  }, []);
  return useMemo(() => [showResultHasChanged, handleAnimationEnd], [showResultHasChanged, handleAnimationEnd]);
};
setGlobal('svs.components.poolBetting.hooks.useEventUpdates', useEventUpdates);

 })(window);